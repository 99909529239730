import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// POST
const getStructureByFilter = filter => RequestHelper.POST(API_ROUTES.STRUCTURE_BY_FILTER(), filter);

const StructureService = {
  getStructureByFilter
};

export default StructureService;