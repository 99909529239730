import React, { Component } from 'react';
import { translate } from 'utils';
import { Button, Grid, Typography } from '@material-ui/core';
import { FullWrapper } from 'components';
import { Link } from 'react-router-dom';
import { PATHS } from 'utils/constants';

class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  handleBackHome = () => {
    this.setState({
      hasError: false
    });
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <FullWrapper style={{ height: '100vh', width: '50vw' }}>
          <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
            spacing={4}
            style={{ height: '100%' }}
          >
            <Grid item>
              <Typography align="center" variant="h1">
                Oops...
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center" component="h2" variant="h6">
                {translate('errors.errorBoundary')}
              </Typography>
            </Grid>
            <Grid item>
              <img alt={translate('errors.errorBoundary')} src="/assets/images/error.png" />
            </Grid>
            <Grid item>
              <Link to={PATHS.HOME} onClick={this.handleBackHome}>
                <Button aria-label={translate('button.home')} color="secondary">{translate('button.home')}</Button>
              </Link>
            </Grid>
          </Grid>
        </FullWrapper>

      );
    }

    return children;
  }
}

export default ErrorBoundary;