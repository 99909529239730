/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useState } from 'react';

import {
  Button,
  Typography,
  FormHelperText,
  Grid,
  TextField
} from '@material-ui/core';

import { translate } from 'utils';
import { StructureService } from 'services';
import { Autocomplete } from '@material-ui/lab';
import { AmendmentHelper } from 'utils/helpers';
import { SCREENPLAYS, STRUCTURE_TYPE_TO_SEARCH_BY_SCREENPLAY } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const NO_SIRET = 'Aucun SIRET';
const NO_SIREN = 'Aucun SIREN';
const NO_STRUCTURE_NAME = 'Aucune raison sociale';
const NO_REGISTRATION_NUMBER = 'Aucun matricule';

const CompanySearch = ({
  formState, direction = 'row', setNotFoundError, disabled = false, setIsLoading, isLoading, takeover = false
}) => {
  const [companies, setCompanies] = useState([]);
  const [identificationNumbers, setIdentificationNumbers] = useState([]);
  const [registrationNumbers, setRegistrationNumbers] = useState([]);
  const structureTypeToSearch = STRUCTURE_TYPE_TO_SEARCH_BY_SCREENPLAY.find(obj => obj.screenplays.includes(formState.values.amendmentScreenplay)).stuctureType || 1;
  const identificationNumberField = structureTypeToSearch === 1 ? 'siren' : 'siret';
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleServiceError = err => {
    enqueueSnackbar(`${err.message}`, { variant: 'error' });
    if (err.statusCode === 422) {
      setTimeout(() => history.push(PATHS.CONNECT), 3000);
    }
  };

  const handleSearch = useCallback(() => {
    setIsLoading(true);
    let error = false;
    formState.setFieldError('amendmentStructureName', false);
    formState.setFieldError('amendmentStructureIdentificationNumber', false);
    if (!formState.values.amendmentStructureName) {
      formState.setFieldError('amendmentStructureName', true);
      error = true;
    }
    if (!formState.values.amendmentStructureIdentificationNumber) {
      formState.setFieldError('amendmentStructureIdentificationNumber', true);
      error = true;
    }

    if (error) return;

    const filter = {
      paging: {
        skip: 0,
        itemsCount: 20
      },
      sorting: [
        {
          order: 0,
          isAscending: true,
          columnName: 'Name'
        }
      ],
      structureName: formState.values.amendmentStructureName,
      structureType: structureTypeToSearch
    };

    if (structureTypeToSearch === 1) {
      if (formState.values.amendmentStructureIdentificationNumber !== NO_SIREN) {
        filter.siren = formState.values.amendmentStructureIdentificationNumber;
      }
    } else if (formState.values.amendmentStructureIdentificationNumber !== NO_SIRET) {
      filter.siret = formState.values.amendmentStructureIdentificationNumber;
    }
    StructureService.getStructureByFilter(filter).then(resp => {
      const structure = resp?.find(e => e.registrationNumber === formState?.values?.amendmentStructureRegistrationNumber) || resp[0];
      if (!structure) {
        setNotFoundError(true);
        return;
      }
      if (formState.values.amendmentScreenplay === SCREENPLAYS.COMPANY_ENROLMENT
        || formState.values.amendmentScreenplay === SCREENPLAYS.COMPANY_RESIGNATION
      ) {
        if (!structure.establishments.$values.some(establishment => establishment.branches.$values.length > 0)) {
          setNotFoundError(false);
          setIsLoading(false);
          formState.setFieldError('noBranchForCurrentSeason', true);
          return;
        }
        formState.setFieldError('noBranchForCurrentSeason', false);
      } else if (formState.values.amendmentScreenplay === SCREENPLAYS.ESTABLISHMENT_REACTIVATION
        || formState.values.amendmentScreenplay === SCREENPLAYS.ESTABLISHMENT_SUSPENSION) {
        if (!structure.branches.$values.length) {
          setNotFoundError(false);
          setIsLoading(false);
          formState.setFieldError('noBranchForCurrentSeason', true);
          return;
        }
        formState.setFieldError('noBranchForCurrentSeason', false);
      }
      if (takeover) {
        const newBuyer = {};
        AmendmentHelper.formatAmendmentData(newBuyer, [], structure, 'amendmentCompany').then(() => {
          formState.setField('newBuyer', newBuyer);
          setNotFoundError(false);
          setIsLoading(false);
          formState.setFieldError('noBranchForCurrentSeason', false);
        });
      } else if (structureTypeToSearch === 1) {
        AmendmentHelper.formatAmendmentDataFormState(formState, ['legalForm'], structure, 'amendmentCompany').then(() => {
          setNotFoundError(false);
          setIsLoading(false);
          formState.setFieldError('noBranchForCurrentSeason', false);
        });
      } else {
        const establishment = {};
        AmendmentHelper.formatAmendmentData(establishment, [], structure, 'amendmentEstablishment').then(() => {
          formState.setField('amendmentEstablishments', [establishment]);
          setNotFoundError(false);
          setIsLoading(false);
          formState.setFieldError('noBranchForCurrentSeason', false);
        });
      }
    }).catch(err => {
      setNotFoundError(true);
      setIsLoading(false);
      handleServiceError(err);
    });
  }, [formState.values]);

  const returnIndentificationNumber = structure => {
    if (!structure) return '';
    if (structureTypeToSearch === 1) {
      if (structure.siren) {
        return structure.siren;
      }
      return 'Aucun SIREN';
    } if (structure.siret) {
      return structure.siret;
    }
    return 'Aucun SIRET';
  };

  const handleOnSelectStructureName = structureName => {
    formState.setField('amendmentCompanyId', null);
    setIsLoading(true);
    formState.setField('amendmentStructureName', structureName);
    const filter = {
      paging: {
        skip: 0,
        itemsCount: 20
      },
      sorting: [
        {
          order: 0,
          isAscending: true,
          columnName: 'Name'
        }
      ],
      structureName: `${structureName}%`,
      structureType: structureTypeToSearch
    };

    StructureService.getStructureByFilter(filter).then(resp => {
      if (resp.length > 0) {
        setIdentificationNumbers(resp.map(company => returnIndentificationNumber(company)));
        const identificationNumber = returnIndentificationNumber(resp[0]);
        formState.setField('amendmentStructureIdentificationNumber', !identificationNumber ? structureTypeToSearch === 1 ? NO_SIREN : NO_SIRET : identificationNumber);
        setRegistrationNumbers(resp.map(company => company.registrationNumber));
        const { registrationNumber } = resp[0];
        formState.setField('amendmentStructureRegistrationNumber', registrationNumber ?? NO_REGISTRATION_NUMBER);
      }
      setIsLoading(false);
      formState.setFieldError('noBranchForCurrentSeason', false);
    }).catch(err => {
      handleServiceError(err);
    });
  };

  const handleOnSelectRegistrationNumber = registrationNumber => {
    formState.setField('amendmentCompanyId', null);
    setIsLoading(true);
    formState.setField('amendmentStructureRegistrationNumber', registrationNumber);
    const filter = {
      paging: {
        skip: 0,
        itemsCount: 20
      },
      sorting: [
        {
          order: 0,
          isAscending: true,
          columnName: 'StructureCode'
        }
      ],
      registrationNumber: `${registrationNumber}%`,
      structureType: structureTypeToSearch
    };

    StructureService.getStructureByFilter(filter).then(resp => {
      if (resp.length > 0) {
        setCompanies(resp.map(structure => structure.structureName));
        const { structureName } = resp[0];
        formState.setField('amendmentStructureName', structureName ?? NO_STRUCTURE_NAME);
        setIdentificationNumbers(resp.map(company => returnIndentificationNumber(company)));
        const identificationNumber = returnIndentificationNumber(resp[0]);
        formState.setField('amendmentStructureIdentificationNumber', !identificationNumber ? structureTypeToSearch === 1 ? NO_SIREN : NO_SIRET : identificationNumber);
      }
      setIsLoading(false);
      formState.setFieldError('noBranchForCurrentSeason', false);
    }).catch(err => {
      handleServiceError(err);
    });
  };

  const handleOnSelectIdentificationNumber = identificationNumber => {
    formState.setField('amendmentCompanyId', null);
    setIsLoading(true);
    formState.setField('amendmentStructureIdentificationNumber', identificationNumber);
    const filter = {
      paging: {
        skip: 0,
        itemsCount: 20
      },
      sorting: [
        {
          order: 0,
          isAscending: true,
          columnName: identificationNumberField
        }
      ],
      structureType: structureTypeToSearch
    };

    if (structureTypeToSearch === 1) {
      filter.siren = `${identificationNumber}%`;
    } else {
      filter.siret = `${identificationNumber}%`;
    }

    StructureService.getStructureByFilter(filter).then(resp => {
      if (resp.length > 0) {
        setCompanies(resp.map(structure => structure.structureName));
        const { structureName } = resp[0];
        formState.setField('amendmentStructureName', structureName ?? NO_STRUCTURE_NAME);
        setRegistrationNumbers(resp.map(company => company.registrationNumber));
        const { registrationNumber } = resp[0];
        formState.setField('amendmentStructureRegistrationNumber', registrationNumber ?? NO_REGISTRATION_NUMBER);
      }
      setIsLoading(false);
      formState.setFieldError('noBranchForCurrentSeason', false);
    }).catch(err => {
      handleServiceError(err);
    });
  };

  const handleOnChangeStructureName = event => {
    setIsLoading(true);
    const structureName = event.target.value;
    formState.setField('amendmentStructureName', structureName);

    const filter = {
      paging: {
        skip: 0,
        itemsCount: 30
      },
      sorting: [
        {
          order: 0,
          isAscending: true,
          columnName: 'Name'
        }
      ],
      structureType: structureTypeToSearch
    };
    if (formState.values.amendmentStructureName !== '' || structureName !== '') {
      filter.structureName = `%${structureName ?? formState.values.amendmentStructureName}%`;
    }

    StructureService.getStructureByFilter(filter).then(resp => {
      setCompanies(resp.map(company => company.structureName));
      setIsLoading(false);
      if (resp.length === 1) handleOnSelectStructureName(structureName); // Allow to handle copy/paste
    }).catch(err => {
      handleServiceError(err);
    });
  };

  const handleOnChangeIdentificationNumber = event => {
    setIsLoading(true);
    const identificationNumber = event.target.value;
    formState.setField('amendmentStructureIdentificationNumber', identificationNumber);
    const filter = {
      paging: {
        skip: 0,
        itemsCount: 20
      },
      sorting: [
        {
          order: 0,
          isAscending: true,
          columnName: identificationNumberField
        }
      ],
      structureType: structureTypeToSearch
    };
    if (formState.values.amendmentStructureIdentificationNumber !== '' || identificationNumber !== '') {
      if (structureTypeToSearch === 1) {
        filter.siren = `${identificationNumber ?? formState.values.amendmentStructureIdentificationNumber}%`;
      } else {
        filter.siret = `${identificationNumber ?? formState.values.amendmentStructureIdentificationNumber}%`;
      }
    }

    StructureService.getStructureByFilter(filter).then(resp => {
      setIdentificationNumbers(resp.map(company => returnIndentificationNumber(company)));
      setIsLoading(false);
      if (resp.length === 1)handleOnSelectIdentificationNumber(identificationNumber); // Allow to handle copy/paste
    }).catch(err => {
      handleServiceError(err);
    });
  };

  const handleOnChangeRegistrationNumber = event => {
    setIsLoading(true);
    const registrationNumber = event.target.value;
    formState.setField('amendmentStructureRegistrationNumber', registrationNumber);
    const filter = {
      paging: {
        skip: 0,
        itemsCount: 20
      },
      sorting: [
        {
          order: 0,
          isAscending: true,
          columnName: 'registrationNumber'
        }
      ],
      structureType: structureTypeToSearch
    };
    if (formState.values.amendmentStructureRegistrationNumber !== '' || registrationNumber !== '') {
      filter.RegistrationNumber = `${registrationNumber ?? formState.values.amendmentStructureRegistrationNumber}%`;
    }

    StructureService.getStructureByFilter(filter).then(resp => {
      setRegistrationNumbers(resp.map(company => company.registrationNumber));
      setIsLoading(false);
      if (resp.length === 1)handleOnSelectRegistrationNumber(registrationNumber); // Allow to handle copy/paste
    }).catch(err => {
      handleServiceError(err);
    });
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={9}>
        <Grid container direction={direction} spacing={2}>
          <Grid item xs={takeover ? 12 : 6}>
            <Autocomplete
              data-testid="input-general-name"
              disableClearable
              freeSolo
              options={companies}
              renderInput={params => (
                <TextField
                  fullWidth
                  label="Raison sociale"
                  required
                  variant="outlined"
                  {...params}
                  onChange={handleOnChangeStructureName}
                />
              )}
              value={companies.find(company => company === formState.values.amendmentStructureName) ?? ''}
              onChange={(event, value) => handleOnSelectStructureName(value)}
            />
            {formState.errors.amendmentStructureName && <FormHelperText error>Veuillez remplir ce champ</FormHelperText>}
          </Grid>
          <Grid item xs={takeover ? 8 : 3}>
            <Autocomplete
              data-testid="input-general-number"
              disableClearable
              freeSolo
              options={identificationNumbers}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label={`${structureTypeToSearch === 1 ? 'N°SIREN' : 'N°SIRET'}`}
                  required
                  variant="outlined"
                  onChange={handleOnChangeIdentificationNumber}
                />
              )}
              value={identificationNumbers.find(identificationNumber => identificationNumber === formState.values.amendmentStructureIdentificationNumber) ?? ''}
              onChange={(event, value) => handleOnSelectIdentificationNumber(value)}
            />
            {formState.errors.amendmentStructureIdentificationNumber && <FormHelperText error>Veuillez remplir ce champ</FormHelperText>}
          </Grid>
          <Grid item xs={takeover ? 8 : 3}>
            <Autocomplete
              data-testid="input-general-registrationNumber"
              disableClearable
              freeSolo
              options={registrationNumbers}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label="Matricule"
                  required
                  variant="outlined"
                  onChange={handleOnChangeRegistrationNumber}
                />
              )}
              value={registrationNumbers.find(registrationNumber => registrationNumber === formState.values.amendmentStructureRegistrationNumber) ?? ''}
              onChange={(event, value) => handleOnSelectRegistrationNumber(value)}
            />
            {formState.errors.amendmentStructureRegistrationNumber && <FormHelperText error>Veuillez remplir ce champ</FormHelperText>}
          </Grid>
        </Grid>
      </Grid>
      {!isLoading && (
        <Grid item>
          <Button
            color="primary"
            data-testid="button-general-search"
            disabled={disabled}
            onClick={handleSearch}
          >
            {translate('button.search')}
          </Button>
        </Grid>
      )}
      {isLoading && (
        <Grid item>
          <Typography variant="h1">
            Recherche en cours ...
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default CompanySearch;