import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import {
  DialogActions, DialogContent, Button
} from '@material-ui/core';
import { translate } from 'utils';
import { ModalHeader } from './_ModalHeader';

export const WarningModal = ({
  onClose, onConfirm, onCancel, onAction, text, title, buttonCancel, buttonConfirm, buttonAction
}) => {
  const handleCancel = useCallback(() => {
    onCancel();
    onClose();
  }, [onCancel, onClose]);

  const handleAction = useCallback(() => {
    onAction && onAction();
    onClose();
  }, [onAction, onClose]);

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
      return onClose();
    }

    return onClose();
  }, [onConfirm, onClose]);

  return (
    <>
      <ModalHeader className="warningModal" onClose={onClose}>
        <FontAwesomeIcon icon={faExclamationTriangle} />
        {translate(title)}
      </ModalHeader>

      <DialogContent
        dangerouslySetInnerHTML={{ __html: text }}
        style={{ maxWidth: '500px', margin: '1rem 0' }}
      />

      <DialogActions>
        {buttonCancel && (
          <Button variant="text" onClick={handleCancel}>{translate(buttonCancel)}</Button>
        )}
        {buttonAction && (
          <Button variant="text" onClick={handleAction}>{translate(buttonAction)}</Button>
        )}
        <Button
          color="primary"
          id="warningModalConfirm"
          variant="contained"
          onClick={handleConfirm}
        >
          {translate(buttonConfirm)}
        </Button>
      </DialogActions>
    </>
  );
};

WarningModal.propTypes = {
  buttonAction: PropTypes.string,
  buttonCancel: PropTypes.string,
  buttonConfirm: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]),
  title: PropTypes.string
};

WarningModal.defaultProps = {
  buttonAction: '',
  buttonCancel: '',
  buttonConfirm: 'button.confirm',
  onAction: null,
  onCancel: () => {},
  onConfirm: null,
  text: '',
  title: 'warnings.warning'
};