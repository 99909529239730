/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React from 'react';

import { Typography } from '@material-ui/core';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDay
} from '@fortawesome/pro-regular-svg-icons';
import { AMENDMENT_STATUS_DETAILS } from 'utils/constants';

export const StyledStatusDisplay = styled.aside`
  padding: 1rem;
  margin-bottom: 2rem;
  margin-top: 50px;
  background-color: var(--primary-color-lighter);
  border: 1px solid var(--primary-color-light);
  border-radius: 8px;

  svg {
    margin-right: 1rem;
  }
`;

const StatusDisplay = ({
  formState
}) => (
  <StyledStatusDisplay>
    <Typography component="p" gutterBottom variant="h4">
      {
        'Vous visionnez une modification du fichier central avec pour statut : '
      }
      <span style={{ color: AMENDMENT_STATUS_DETAILS[formState.values.amendmentStatus].color }}>
        {AMENDMENT_STATUS_DETAILS[formState.values.amendmentStatus].label}
      </span>
    </Typography>
    <Typography>
      <strong>
        <FontAwesomeIcon icon={faCalendarDay} />
        {'Date de création : '}
      </strong>
      {new Date(formState.values.amendmentCreateAt).toLocaleDateString()}
    </Typography>
  </StyledStatusDisplay>
);

export default StatusDisplay;