import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Button, DialogActions, DialogContent, Grid
} from '@material-ui/core';
import { translate } from 'utils';
import styled from 'styled-components';
import { INFORMATIONS_WEBSITE } from 'utils/constants';
import { ModalHeader } from './_ModalHeader';
import ExternLink from '../ExternLink/ExternLink';

const InformationsWebsiteContainer = styled.div`
  margin: auto;
  width: 350px;
  padding: 30px;
  background-image: url('/assets/images/container-decorative.png');
  background-position: top;
  border-radius: 8px;
`;

export const LinksModal = ({
  onClose, afterClose, title
}) => {
  const handleClose = useCallback(() => {
    onClose();
    afterClose && afterClose();
  }, [onClose, afterClose]);

  return (
    <>
      <ModalHeader className="errorModal" onClose={onClose}>
        {translate(title)}
      </ModalHeader>

      <DialogContent>
        <Grid item>
          <InformationsWebsiteContainer>
            <Grid container direction="column" spacing={2}>
              {INFORMATIONS_WEBSITE.map(item => (
                <Grid item>
                  <ExternLink label={item.label} link={item.link} />
                </Grid>
              ))}
            </Grid>
          </InformationsWebsiteContainer>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          {translate('button.close')}
        </Button>
      </DialogActions>
    </>
  );
};

LinksModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  afterClose: PropTypes.func,
  title: PropTypes.string
};

LinksModal.defaultProps = {
  afterClose: () => {},
  title: 'errors.default'
};