/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
import './CustomSwitch.scss';

const CustomSwitch = ({
  name,
  checked,
  onChange,
  small = true,
  disabled
}) => (
  <div className={`toggle-switch${small ? ' small-switch' : ''}`} role="button" onClick={() => onChange()} onKeyDown={() => {}}>
    <input
      checked={checked}
      className="toggle-switch-checkbox"
      disabled={disabled}
      name={name}
      type="checkbox"
      onChange={() => {}}
    />
    <label
      className="toggle-switch-label"
      tabIndex={disabled ? -1 : 1}
    >
      <span
        className={
          disabled
            ? 'toggle-switch-inner toggle-switch-disabled'
            : 'toggle-switch-inner'
        }
        data-no=""
        data-yes=""
        tabIndex={-1}
      />
      <span
        className={
          disabled
            ? 'toggle-switch-switch toggle-switch-disabled'
            : 'toggle-switch-switch'
        }
        tabIndex={-1}
      />
    </label>
  </div>
);

CustomSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CustomSwitch;