import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';

const StyledCardTitle = styled.header`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1rem 1rem;
  border-bottom: 1px solid #eeeeee;
  background: var(--info-color);

  button {
    position: absolute;
    right: 1rem;
  }
`;

const ClickableIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const EstablishmentCardTitle = ({
  title, titleColor = 'inherit', trashIconAction, arrowIconAction, isOpen
}) => (
  <StyledCardTitle onClick={arrowIconAction}>
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography
          color={titleColor}
          component="h2"
          variant="h4"
        >
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={2}>
          {trashIconAction && (
            <Grid item>
              <ClickableIcon className="mr1" color="red" icon={faTrashAlt} size="2x" onClick={trashIconAction} />
            </Grid>
          )}
          <Grid item>
            <ClickableIcon className="mr1" color="var(--primary-color)" icon={isOpen ? faAngleDown : faAngleUp} size="2x" onClick={arrowIconAction} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </StyledCardTitle>
);

export default EstablishmentCardTitle;