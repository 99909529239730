import React, { lazy, Suspense, useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'utils/constants';
import { Header, SkeletonMain } from 'components';
import { Grid } from '@material-ui/core';

const PageHome = lazy(() => import('pages/PageHome'));
const PageEnd = lazy(() => import('pages/PageEnd'));
const PageConnect = lazy(() => import('pages/PageConnect'));

const withLayout = BaseComponent => props => (
  <Grid
    container
    direction="column"
    style={{ height: '100%', overflow: 'hidden' }}
    wrap="nowrap"
  >
    <Header />
    <main>
      <BaseComponent {...props} />
    </main>
  </Grid>
);

const RouteWithLayout = ({ component, ...props }) => {
  const handleRouteRender = useCallback(() => withLayout(component)(props), [component]);

  return (
    <Route {...props} render={handleRouteRender} />
  );
};

const Routes = () => (
  <Suspense fallback={SkeletonMain()}>
    <Switch>
      <RouteWithLayout component={PageHome} exact path={PATHS.HOME} />
      <RouteWithLayout component={PageEnd} exact path={PATHS.END} />
      <RouteWithLayout component={PageConnect} exact path={PATHS.CONNECT} />
      <Redirect to={PATHS.CONNECT} />
    </Switch>
  </Suspense>
);

export default Routes;