/* eslint-disable consistent-return */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-console */
import promiseRetry from 'promise-retry';
import { UserService } from 'services';
import { REFRESH_TOKEN_NAME } from 'utils/constants';
import { translate } from 'utils';
import { CookieHelper } from '.';
import { httpSettings } from './request';

export class HTTPError401 extends Error {
  constructor(code, response) {
    super();
    this.statusCode = code;
    this.response = response;
  }
}

export class HTTPError extends Error {
  constructor(code, message) {
    super();
    this.statusCode = code;
    this.message = message;
  }
}

const getErrorMessage = resp => {
  const errorMessage = {
    message: 'Une erreur est survenue',
    variant: 'error'
  };

  if (resp.errors && resp.errors.length > 0) {
    errorMessage.message = resp.errors[0].detail;
  } else if (resp.information && resp.information.length > 0) {
    errorMessage.message = resp.information[0].detail;
    errorMessage.variant = 'info';
  } else if (resp.warnings && resp.warnings.length > 0) {
    errorMessage.message = resp.warnings[0].detail;
    errorMessage.variant = 'warning';
  }

  return errorMessage;
};

const handleRefreshToken = () => {
  const refreshToken = CookieHelper.getCookie(REFRESH_TOKEN_NAME);

  if (refreshToken) {
    return UserService.refreshToken(refreshToken).then(refresh => {
      if (refresh.data) {
        return CookieHelper.createCookies(refresh.data);
      }

      const errorMessage = getErrorMessage(refresh);
      throw errorMessage;
    })
      .catch(error => { throw error; });
  }

  // eslint-disable-next-line prefer-promise-reject-errors
  return new Promise((_, reject) => reject({ message: 'Votre session a expiré', variant: 'warning' }));
};

const defaultResponseHandler = async response => {
  const { status } = response;

  let errorMessage = translate('errors.default');

  switch (status) {
  case 200:
  case 201:
    return response.json();
  case 204:
    return true;
  case 422:
    throw new HTTPError(status, 'Vous n\'êtes plus connecté');
  case 401:
    throw new HTTPError401(status, response);
  default:
    await response.json().then(data => {
      if (data.Message) errorMessage = data.Message;
    });
    throw new HTTPError(status, errorMessage);
  }
};

export const refreshTokenOn401 = request => new Promise((resolve, reject) => {
  promiseRetry(retry => request()
    .catch(async err => {
      if (err.statusCode === 401) {
        await handleRefreshToken();
        retry(err);
      }
      throw err;
    }))
    .then(value => {
      resolve(value);
    }, err => {
      reject(err);
    });
});

const GET = (url, headers) => refreshTokenOn401(() => fetch(url, httpSettings({
  headers,
  method: 'GET'
})).then(defaultResponseHandler)).catch(err => { throw err; });

const POST = (url, body = {}, headers = null) => refreshTokenOn401(() => fetch(url, httpSettings({
  method: 'POST',
  headers,
  body
})).then(defaultResponseHandler)).catch(err => { throw err; });

const POST_WITHOUT_HEADER = (url, body = {}) => refreshTokenOn401(() => fetch(url, {
  method: 'POST',
  body
}).then(defaultResponseHandler)).catch(err => { throw err; });

const PUT = (url, body = {}, headers = null) => refreshTokenOn401(() => fetch(url, httpSettings({
  method: 'PUT',
  headers,
  body
})).then(defaultResponseHandler)).catch(err => { throw err; });

const PATCH = (url, body = {}, headers = null) => refreshTokenOn401(() => fetch(url, httpSettings({
  method: 'PATCH',
  headers,
  body
})).then(defaultResponseHandler)).catch(err => { throw err; });

const DELETE = (url, body = {}, headers = null) => refreshTokenOn401(() => fetch(url, httpSettings({
  method: 'DELETE',
  headers,
  body
})).then(defaultResponseHandler)).catch(err => { throw err; });

export const RequestHelper = {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
  POST_WITHOUT_HEADER
};