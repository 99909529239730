import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const searchAmendmentById = id => RequestHelper.GET(API_ROUTES.AMENDMENT_BY_ID(id));

// POST

const createAmendment = amendment => RequestHelper.POST(API_ROUTES.CREATE_AMENDMENT(), amendment);

const filterAmendments = filter => RequestHelper.POST(API_ROUTES.FILTER_AMENDMENTS(), filter);

// PUT

const updateAmendment = (id, amendment) => RequestHelper.PUT(API_ROUTES.UPDATE_AMENDMENT(id), amendment);

const updateAmendmentStatus = (id, status) => RequestHelper.PUT(API_ROUTES.UPDATE_AMENDMENT_STATUS(id), status);

const updateAmendmentValidation = (id, amendmentValidation) => RequestHelper.PUT(API_ROUTES.UPDATE_AMENDMENT_VALIDATION(id), amendmentValidation);

const AmendmentService = {
  searchAmendmentById,
  createAmendment,
  filterAmendments,
  updateAmendment,
  updateAmendmentStatus,
  updateAmendmentValidation
};

export default AmendmentService;