import React from 'react';
import {
  Grid, Table, TableBody, TableCell, TableRow, Typography
} from '@material-ui/core';
import styled from 'styled-components';

const LegendGrid = styled(Grid)`
  margin-left: 20px;
  .status-added{
    color: var(--green-text);
  }
  .status-deleted{
    color: var(--delete-color);
  }
  .status-modified{
    color: var(--orange-text);
  }
  .status-old{
    color: grey;
  }
`;

const Legend = () => (
  <LegendGrid item>
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography component="h2" variant="h5">Légende</Typography>
      </Grid>
      <Grid item>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography className="status-modified">
                  Orange
                </Typography>
              </TableCell>
              <TableCell>
                <Grid item>
                  <Typography>
                    {' Informations modifiées'}
                  </Typography>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography className="status-deleted">
                  Rouge
                </Typography>
              </TableCell>
              <TableCell>
                <Grid item>
                  <Typography>
                    {' Informations supprimées'}
                  </Typography>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography className="status-added">
                  Vert
                </Typography>
              </TableCell>
              <TableCell>
                <Grid item>
                  <Typography>
                    {' Informations ajoutées'}
                  </Typography>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography className="status-old">
                  Gris
                </Typography>
              </TableCell>
              <TableCell>
                <Grid item>
                  <Typography>
                    {' Informations antérieures a la saison courante'}
                  </Typography>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  </LegendGrid>
);

export default Legend;