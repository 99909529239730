import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BANNER_COLORS } from '../../../utils/constants';

const BannerMessageContainer = styled.div`
  @keyframes enteringAnimation {
   from {
    transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  animation: 0.5s ease-out 0s 1 enteringAnimation;
  width:100%;
  text-align:center;
  color:var(--white);
  padding: 1rem 6rem;
  font-weight:bold;
  font-size:1.8rem;
`;

const getBannerColor = type => BANNER_COLORS.find(color => color.value === type).color;

const BannerMessage = ({ children, type }) => (
  <BannerMessageContainer style={{ backgroundColor: `var(${getBannerColor(type)})` }}>
    {children}
  </BannerMessageContainer>
);

BannerMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  type: PropTypes.oneOf([
    'error',
    'warning'
  ])
};

BannerMessage.defaultProps = {
  type: 'error'
};

export default BannerMessage;