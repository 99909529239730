import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';

const isFieldRequired = ({ fieldsToCheck = [] }) => fieldsToCheck.some(field => field);

const formatDate = date => format(new Date(date), 'yyyy/MM/dd', { locale: frLocale });

const displayDateAndTime = date => `${new Date(date).toLocaleDateString()} à ${new Date(date).toLocaleTimeString()}`;

const formatDateFromBackend = date => {
  const dateSplit = date.split('/');
  const dateString = `${dateSplit[1]}/${dateSplit[0]}/${dateSplit[2]}`;

  return dateString;
};
const getDefaultDocument = doc => ({
  name: doc,
  content: ''
});

const getCanSubmit = canBook => {
  let result = true;
  canBook.forEach(item => {
    if (!item.value) {
      result = false;
    }
  });
  return result;
};

const getFormattedAddress = form => ({
  address1: form.address1 || '',
  address2: form.address2 || '',
  address3: form.address3 || '',
  city: form.city || '',
  postalCode: form.postalCode || '',
  country: (form.country && form.country.value) || ''
});

const getExternalURL = url => {
  if (!url) return 'https://';
  if (url.includes('https') || url.includes('http')) {
    return url;
  }
  return `http://${url}`;
};

const capitalize = string => `${string.charAt(0).toUpperCase()}${string.substr(1)}`;

const unCapitalize = string => `${string.charAt(0).toLowerCase()}${string.substr(1)}`;

export const FormHelper = {
  isFieldRequired,
  formatDate,
  displayDateAndTime,
  formatDateFromBackend,
  getDefaultDocument,
  getCanSubmit,
  getFormattedAddress,
  getExternalURL,
  capitalize,
  unCapitalize
};