import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// POST
const addStructureInAmendment = (idAmendment, idStructure) => RequestHelper.POST(API_ROUTES.ADD_STRUCTURE_IN_AMENDMENT(idAmendment, idStructure));

const createStructureInAmendment = (idAmendment, structure) => RequestHelper.POST(API_ROUTES.CREATE_STRUCTURE_IN_AMENDMENT(idAmendment), structure);

const createMultiStructureInAmendment = (idAmendment, structureList) => RequestHelper.POST(API_ROUTES.CREATE_MULTI_STRUCTURE_IN_AMENDMENT(idAmendment), structureList);

// PUT
const updateStructureInAmendment = (idAmendment, idAmendmentStructure, structure) => RequestHelper.PUT(API_ROUTES.UPDATE_STRUCTURE_IN_AMENDMENT(idAmendment, idAmendmentStructure), structure);

const updateMultiStructureInAmendment = (idAmendment, structureList) => RequestHelper.PUT(API_ROUTES.UPDATE_MULTI_STRUCTURE_IN_AMENDMENT(idAmendment), structureList);

const disbarStructureInAmendment = (idAmendment, idAmendmentStructure, structure) => RequestHelper.PUT(API_ROUTES.DISBAR_STRUCTURE_IN_AMENDMENT(idAmendment, idAmendmentStructure), structure);

const AmendmentStructureService = {
  addStructureInAmendment,
  createStructureInAmendment,
  createMultiStructureInAmendment,
  updateStructureInAmendment,
  updateMultiStructureInAmendment,
  disbarStructureInAmendment
};

export default AmendmentStructureService;