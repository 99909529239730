import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

const SkeletonContainer = styled.article`
  svg {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
  }
`;

const SkeletonMain = () => (
  <SkeletonContainer>
    <FontAwesomeIcon color="var(--secondary-color)" icon={faSpinner} size="5x" spin />
  </SkeletonContainer>
);

export default SkeletonMain;