import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, InputLabel, Typography } from '@material-ui/core';
import { DocumentHelper, translate } from 'utils';
import { useSnackbar } from 'notistack';

const CustomInputFile = styled.div`
  max-width: 300px;
  padding: 0.8rem;
  border: 1px solid var(--grey-light);
  transition: border var(--transitionTime);
`;

const FormInputFileContainer = styled.div`
  position: relative;
  width: 100%;

  &:hover {
    ${CustomInputFile} {
      border-color: var(--grey-dark);
    }
  }

  label {
    cursor: pointer;
  }

  input[type="file"] {
    height: 1px;
    margin: 0;
    padding: 0;
    border: none;
    opacity: 0;
    cursor: default;
  }
`;

const FormInputFile = ({
  label, name, document, handleFileAdded,
  id, disabled, fileAccepted, required
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleFileChange = useCallback(event => {
    DocumentHelper.handleFileUpload(event)
      .then(doc => handleFileAdded(doc))
      .catch(error => enqueueSnackbar(translate(error.message), { variant: 'error' }));
  }, [handleFileAdded, enqueueSnackbar]);

  return (
    <FormInputFileContainer>
      <InputLabel htmlFor={`file${id}`}>
        <Grid alignItems="center" container spacing={2} wrap="nowrap">
          {label && (
            <Grid item>
              {translate(label)}
              {required && ' *'}
            </Grid>
          )}
          <Grid item>
            <CustomInputFile>
              {document
                ? <Typography noWrap>{document.name}</Typography>
                : <Typography noWrap>{translate('forms.selectFile')}</Typography>}
            </CustomInputFile>
          </Grid>
        </Grid>
      </InputLabel>

      <input
        accept={fileAccepted}
        disabled={disabled}
        id={`file${id}`}
        name={name}
        required={required}
        type="file"
        onChange={handleFileChange}
      />
    </FormInputFileContainer>
  );
};

FormInputFile.propTypes = {
  document: PropTypes.shape({}),
  disabled: PropTypes.bool,
  handleFileAdded: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  label: PropTypes.string,
  fileAccepted: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool
};

FormInputFile.defaultProps = {
  disabled: false,
  document: null,
  fileAccepted: '.pdf, .doc, .docx, .jpg, .jpeg, .png',
  id: 1,
  label: '',
  name: '',
  required: false
};

export default FormInputFile;