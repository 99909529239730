// eslint-disable-next-line no-underscore-dangle
const getEnv = key => (window._env && window._env[`REACT_APP_${key}`])
  || process.env[`REACT_APP_${key}`]
  || '';

export const ROOT_PATH = getEnv('ROOT_PATH');
export const API_URL = getEnv('API_URL');
export const API_URL_SECURITY = getEnv('API_URL_SECURITY');
export const INTRANET_URL = getEnv('INTRANET_URL');
export const REFRESH_TOKEN_NAME = getEnv('REFRESH_TOKEN_NAME');

export const APP_VERSION = getEnv('VERSION');

export const PATHS = {
  HOME: `${ROOT_PATH}modification`,
  CONNECT: `${ROOT_PATH}connexion`,
  END: `${ROOT_PATH}finalisation`
};

export const ROUTES = {
  ...PATHS
  // ALLOWANCE_SOCIAL_CATEGORY: category => `${ROOT_PATH}prestations-sociales/${category}`,
};

export const API_ROUTES = {
  LOGIN_REFRESH: () => `${API_URL_SECURITY}/Login/refreshToken`,
  COMPANY_DETAILS_BY_ID: id => `${API_URL}/CompanyDetails/${id}`,
  COMPANIES: () => `${API_URL}/Companies`,
  SEND_FORM_DATA: () => `${API_URL}/FormData`,
  SEND_FORM_DATA_TEMP: () => `${API_URL}/FormDataTemp`,

  AMENDMENT_BY_ID: id => `${API_URL}/api/amendments/${id}`,
  CREATE_AMENDMENT: () => `${API_URL}/api/amendments`,
  FILTER_AMENDMENTS: () => `${API_URL}/api/amendments/filter`,
  UPDATE_AMENDMENT: id => `${API_URL}/api/amendments/${id}`,
  UPDATE_AMENDMENT_STATUS: id => `${API_URL}/api/amendments/${id}/status`,
  UPDATE_AMENDMENT_VALIDATION: id => `${API_URL}/api/amendments/${id}/validation`,

  STRUCTURE_BY_FILTER: () => `${API_URL}/api/Structures`,

  ADD_STRUCTURE_IN_AMENDMENT: (idAmendment, idStructure) => `${API_URL}/api/amendments/${idAmendment}/structures/${idStructure}`,
  CREATE_STRUCTURE_IN_AMENDMENT: idAmendment => `${API_URL}/api/amendments/${idAmendment}/structures`,
  CREATE_MULTI_STRUCTURE_IN_AMENDMENT: idAmendment => `${API_URL}/api/amendments/${idAmendment}/CreateStructureList`,
  UPDATE_STRUCTURE_IN_AMENDMENT: (idAmendment, idAmendmentStructure) => `${API_URL}/api/amendments/${idAmendment}/amendmentStructures/${idAmendmentStructure}`,
  UPDATE_MULTI_STRUCTURE_IN_AMENDMENT: idAmendment => `${API_URL}/api/amendments/${idAmendment}/UpdateStructureList`,

  DISBAR_STRUCTURE_IN_AMENDMENT: (idAmendment, idAmendmentStructure) => `${API_URL}/api/amendments/${idAmendment}/amendmentStructures/${idAmendmentStructure}/DisbarStructure`,

  GET_ALL_BRANCHES: () => `${API_URL}/api/Branches`,

  GET_ADMINISTRATIVE_DEPARTMENTS: () => `${API_URL}/api/AdministrativeDepartments`,

  GET_CURRENT_SEASON_ID: () => `${API_URL}/api/Seasons`,

  HEALTH_CHECK: `${API_URL}/health`,
  PING: `${API_URL}/ping`
};

export const BANNER_COLORS = [
  { value: 'error', color: '--primary-color' },
  { value: 'warning', color: '--tertiary-color' }
];

export const STEP_DETAILS = [
  {
    label: 'Nature du besoin',
    labelMobile: 'Nature'
  },
  {
    label: 'Source de l\'information',
    labelMobile: 'Source'
  },
  {
    label: 'Général',
    labelMobile: 'Général'
  },
  {
    label: 'Détails',
    labelMobile: 'Détails'
  },
  {
    label: 'Détails (suite)',
    labelMobile: 'Détails 2',
    screenplays: ['3']
  },
  {
    label: 'Vérification et observations',
    labelMobile: 'Vérification'
  }
];

export const STEPS = {
  NATURE: 0,
  SOURCE: 1,
  GENERAL: 2,
  DETAILS: 3,
  COMPLEMENTS: 4,
  VERIFICATION: 4
};

export const NEEDS_NATURE = [
  {
    id: 1,
    label: 'Création / Modification',
    subNatures: [
      {
        label: 'Création',
        companyValue: '1',
        establishmentValue: '2'
      },
      {
        label: 'Modification',
        companyValue: '7',
        establishmentValue: '4'
      },
      {
        label: 'Reprise',
        companyValue: null, // '14',
        establishmentValue: null
      },
      {
        label: 'Rachat',
        companyValue: null,
        establishmentValue: '3'
      }
    ]
  },
  {
    id: 2,
    label: 'Adhésion / Démission',
    subNatures: [
      {
        label: 'Adhésion',
        companyValue: '8',
        establishmentValue: null
      },
      {
        label: 'Démission',
        companyValue: '9',
        establishmentValue: null
      },
      {
        label: 'Exclusion',
        companyValue: null, // '16',
        establishmentValue: null
      }
    ]
  },
  {
    id: 3,
    label: 'Arret / Remise en activité',
    subNatures: [
      {
        label: 'Radiation',
        companyValue: '5',
        establishmentValue: '6'
      },
      {
        label: 'Remise en activité',
        companyValue: null, // '14',
        establishmentValue: '11'
      },
      {
        label: 'Suspension d\'activité',
        companyValue: null, // '13',
        establishmentValue: '12'
      }
    ]
  }
];

export const INFORMATION_SOURCE = {
  ADDRESS: {
    label: 'Adresse postale ou mail invalide (NPAI)',
    value: '1'
  },
  INFORMATION: {
    label: 'Information de l\'entreprise',
    value: '2'
  },
  INTERN_INFORMATION: {
    label: 'Information en interne (services, branches, regions)',
    value: '3'
  },
  MEDIA: {
    label: 'Presse, internet, différents médias, etc...',
    value: '4'
  },
  OTHER: {
    label: 'Autres (réunions, échanges, etc)',
    value: '5'
  }
};

export const INFORMATION_ORIGIN = [
  {
    label: 'Origine',
    value: '0'
  },
  {
    label: 'Mail',
    value: '1',
    originPlaceholder: 'Reçu de'
  },
  {
    label: 'Courrier',
    value: '2',
    originPlaceholder: 'Reçu de'
  },
  {
    label: 'Bulletin',
    value: '3'
  },
  {
    label: 'Entretien',
    value: '4',
    originPlaceholder: 'Avec qui'
  },
  {
    label: 'Recherche',
    value: '5'
  },
  {
    label: 'Téléphone',
    value: '6',
    originPlaceholder: 'De qui'
  },
  {
    label: 'Retours d\'enquête',
    value: '7'
  },
  {
    label: 'Autre',
    value: '8',
    originPlaceholder: 'Précisez'
  }
];

export const TYPE = {
  0: {
    target: 'company',
    label: 'Groupe'
  },
  1: {
    target: 'company',
    label: 'Filiale'
  },
  2: {
    target: 'company',
    label: 'Entreprise'
  },
  3: {
    target: 'establishment',
    label: 'Établissement'
  }
};

export const LEGAL_STATUS = [
  {
    label: 'E.I.R.L',
    value: '0'
  },
  {
    label: 'E.U.R.L',
    value: '1'
  },
  {
    label: 'Individuel',
    value: '2'
  },
  {
    label: 'S.A.S.U',
    value: '3'
  },
  {
    label: 'S.A.R.L',
    value: '4'
  },
  {
    label: 'S.A',
    value: '5'
  },
  {
    label: 'S.A.S',
    value: '6'
  },
  {
    label: 'S.N.C',
    value: '7'
  }
  // {
  //   label: 'Autre (à préciser)',
  //   value: '8'
  // }
];

export const OTHER_LEGAL_STATUS_VALUE = '8';

export const INFORMATIONS_WEBSITE = [
  {
    link: 'https://www.bodacc.fr/',
    label: 'BODACC.FR'
  },
  {
    link: 'https://www.lemoniteur.fr/',
    label: 'LEMONITEUR'
  },
  {
    link: 'https://www.societe.com/',
    label: 'SOCIETE.COM'
  },
  {
    link: 'https://www.insee.fr/fr/accueil',
    label: 'INSEE'
  },
  {
    link: 'https://infobref.com/',
    label: 'INFOBREF'
  }
];

export const REGIONS = [
  {
    name: 'Occitanie',
    status: 'refuse'
  },
  {
    name: 'Grand-Est',
    status: 'waiting'
  },
  {
    name: 'PACA pour vous',
    status: 'valid'
  }
];

export const REGIONS_STATUS = [
  {
    value: 'waiting',
    label: 'Attente'
  },
  {
    value: 'valid',
    label: 'Validé'
  },
  {
    value: 'refuse',
    label: 'Refusé'
  }
];

export const AMENDMENT_STATUS_DETAILS = [
  {
    id: 0,
    label: 'Etat inconnu',
    value: 'Unknown ',
    color: 'var(--grey-darker)'
  },
  {
    id: 1,
    label: 'Dossier créé',
    value: 'Created',
    color: 'var(--primary-color-light)'
  },
  {
    id: 2,
    label: 'Dossier soumis, en cours d\'approbation',
    value: 'Submit',
    color: 'var(--warning-color-dark)'
  },
  {
    id: 3,
    label: 'Dossier terminé, les modifications ont été appliquées',
    value: 'Closed',
    color: 'var(--success-color-dark)'
  },
  {
    id: 4,
    label: 'Dossier abandonné',
    value: 'Abandonned',
    color: 'var(--error-color-dark)'
  }
];

export const AMENDMENT_STATUS = {
  UNKNOWN: 0,
  CREATED: 1,
  SUBMITTED: 2,
  CLOSED: 3,
  ABANDONNED: 4
};

export const SCREENPLAYS = {
  COMPANY_CREATION: '1',
  ESTABLISHMENT_CREATION: '2',
  ESTABLISHMENT_MODIFICATION: '4',
  COMPANY_MODIFICATION: '7',
  COMPANY_TAKEOVER: '14',
  ESTABLISHMENT_TAKEOVER: '3',
  COMPANY_ENROLMENT: '8',
  COMPANY_RESIGNATION: '9',
  ESTABLISHMENT_REACTIVATION: '11',
  ESTABLISHMENT_SUSPENSION: '12',
  COMPANY_DISBAR: '5',
  ESTABLISHMENT_DISBAR: '6'
};

export const SCREENPLAYS_MAPPER = {
  company: ['1', '7', '14', '8', '9'],
  establishment: ['2', '4', '3', '11', '12']
};

export const ESTABLISHMENT_NATURE = {
  0: {
    target: 'BUREAUX_SEULS',
    label: 'Bureaux seuls'
  },
  1: {
    target: 'DEPOT',
    label: 'Dépot'
  },
  2: {
    target: 'ETABLIS_PRODUCTIF',
    label: 'Établissement productif'
  },
  3: {
    target: 'PROD_HORS_STAT',
    label: 'Production hors stat'
  }
};

export const ACTIVITIES_BRANCHES_EXAMPLE = [
  {
    type: 100,
    label: 'PRODUITS EN BETON'
  },
  {
    type: 130,
    label: 'BETON PRET A L\'EMPLOI'
  }
];

export const ORIGINAL_FORMSTATE_VALUES_COMPANY = {
  amendmentCompanyContacts: [],
  amendmentCompanyHeadquarterAddress: {},
  amendmentCompanyCorrespondingAddress: {},
  amendmentCompanyIsCraftman: false,
  amendmentCompanyOtherActivities: '',
  amendmentCompanyAdministrativeDepartment: {
    id: 108, label: 'Choisir un département'
  },
  amendmentCompanyResponsibleStructure: {
    id: 0, label: 'Choisir une région'
  },
  amendmentCompanyHasJoinGIE: false
};

export const ORIGINAL_FORMSTATE_VALUES_ESTABLISHMENT_CONTACT = {
  lastName: '',
  firstName: '',
  function: '',
  phoneNumber: '',
  mail: '',
  status: 'added'
};

export const ORIGINAL_FORMSTATE_VALUES_ESTABLISHMENT = {
  amendmentEstablishmentBranches: [],
  amendmentEstablishmentHeadquarterAddress: {},
  amendmentEstablishmentCorrespondingAddress: {},
  amendmentEstablishmentNatureActivity: 0,
  amendmentEstablishmentIsOnActivity: false,
  amendmentEstablishmentResponsibleStructure: {
    id: 0, label: 'Choisir une région'
  },
  amendmentEstablishmentAdministrativeDepartment: {
    id: 108, label: 'Choisir un département'
  },
  isOpen: true,
  error: false,
  amendmentEstablishmentContacts: []
};

export const BRANCH_SITUATION = {
  0: {
    color: 'var(--orange-text)',
    label: 'SUSPENDUE'
  },
  1: {
    color: 'var(--green-text)',
    label: 'EN ACTIVITÉ'
  },
  2: {
    color: 'var(--delete-color)',
    label: 'RADIÉ'
  },
  3: {
    color: 'var(--green-text)',
    label: 'REMIT EN ACTIVITÉ'
  }
};

export const BRANCH_SITUATION_MAPPER = {
  SUSPENDUE: 0,
  EN_ACTIVITE: 1,
  RADIE: 2,
  REMIT_EN_ACTIVITE: 3
};

export const STRUCTURE_TYPE_TO_SEARCH_BY_SCREENPLAY = [
  {
    stuctureType: 1,
    screenplays: ['1', '2', '3', '7', '8', '9', '5']
  },
  {
    stuctureType: 2,
    screenplays: ['4', '11', '12', '6']
  }
];

export const STRUCTURE_TYPE_TRADE_UNIONS = 4;
export const STRUCTURE_TYPE_REGIONS = 3;

export const CONTACT_ADDED = 'added';
export const CONTACT_MODIFIED = 'modified';
export const CONTACT_DELETED = 'deleted';

export const BRANCH_TYPE = {
  UNKNOWN: 0,
  JOINING: 1,
  STRINKING_OFF: 2,
  RESIGNATION: 3,
  ACTIVITY_SUSPENSION: 4,
  ACTIVITY_RESTARTING: 5
};

export const VALIDATION_STATUS = {
  ACCEPTED: 0,
  WAITING: 1,
  REFUSED: 2
};

export const DISBAR_REASON = {
  NO_ACTIVITY: '0',
  ABSORBED: '1',
  ERROR: '2'
};

export const MEMBERSHIP_MAPPER = {
  1: true,
  2: false
};

export const DAY_TIMES = {
  MORNING: 'morning',
  EVENING: 'evening',
  FULL_DAY: 'both'
};