import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getAllBranches = () => RequestHelper.GET(API_ROUTES.GET_ALL_BRANCHES());

const BranchService = {
  getAllBranches
};

export default BranchService;