import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getCurrentSeasonId = () => RequestHelper.GET(API_ROUTES.GET_CURRENT_SEASON_ID());

const SeasonService = {
  getCurrentSeasonId
};

export default SeasonService;