import React from 'react';

import {

  Grid
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from 'styled-components';

const Container = styled(Grid)`
  cursor: pointer;
  border-radius: 10px;
  color: var(--white);
  box-shadow: 0 5px 10px rgba(0,0,0,0.4);

  &[data-color="blue"]{
    background-color: var(--primary-color);
  }
  &[data-color="green"]{
    background-color: var(--tertiary-color);
  }

  &:hover{
    filter: brightness(0.85);
  }
`;

const Text = styled(Grid)`
  padding: 8px;
`;

const ImageButton = ({
  imageUrl, text, color, icon, handleOnClick, testId
}) => (
  <Container container data-color={color} data-testid={testId} direction="column" spacing={0} onClick={handleOnClick}>
    <Grid item>
      <img alt="end" src={imageUrl} style={{ borderRadius: '10px 10px 0 0' }} />
    </Grid>
    <Text item>
      <Grid alignItems="center" container justifyContent="center" spacing={1} wrap="nowrap">
        <Grid item>
          <FontAwesomeIcon icon={icon} size="2x" />
        </Grid>
        <Grid item>
          {text}
        </Grid>
      </Grid>
    </Text>
  </Container>
);

export default ImageButton;