import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getAdministrativeDepartments = () => RequestHelper.GET(API_ROUTES.GET_ADMINISTRATIVE_DEPARTMENTS());

const AdministrativeDepartmentsService = {
  getAdministrativeDepartments
};

export default AdministrativeDepartmentsService;