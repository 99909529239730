import React from 'react';
import { Grid } from '@material-ui/core';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonAllowanceCard = ({ fullItem }) => (
  <>
    <Skeleton height={180} style={{ borderRadius: '8px' }} variant="rect" />
    <Skeleton height={30} />

    {fullItem && (
      <Grid alignItems="center" container direction="column" justifyContent="center">
        <Skeleton height={20} width="60%" />
        <Skeleton height={20} width="40%" />
      </Grid>
    )}
  </>
);

SkeletonAllowanceCard.propTypes = {
  fullItem: PropTypes.bool
};

SkeletonAllowanceCard.defaultProps = {
  fullItem: false
};

export const SkeletonAllowanceCardList = ({ fullItem }) => (
  <Grid container spacing={2}>
    {[...Array(3)].map(() => (
      <Grid item key={shortid.generate()} sm={4} xs={12}>
        <SkeletonAllowanceCard fullItem={fullItem} />
      </Grid>
    ))}
  </Grid>
);

SkeletonAllowanceCardList.propTypes = {
  fullItem: PropTypes.bool
};

SkeletonAllowanceCardList.defaultProps = {
  fullItem: false
};

export default SkeletonAllowanceCard;