import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { REGIONS_STATUS } from 'utils/constants';
import { isMobile } from 'utils';

const RegionsContainer = styled(Grid)`
  margin-left: 20px;
`;

const SatutsContainer = styled(Grid)`
  text-align: center;
  min-width: 75px;
  padding: 5px 10px;
  border-radius: 10px;

  .MuiTypography-body1{
    font-weight: bold;
  }

  &[data-status="waiting"]{
    background-color: var(--modified-flag-color);
  }
  &[data-status="valid"]{
    background-color: var(--valid-flag-color);
  }
  &[data-status="refuse"]{
    color: var(--white);
    background-color: var(--deleted-flag-color);
  }
`;

const RegionsStatus = ({ regions }) => {
  const getStatusLabel = status => REGIONS_STATUS.find(RegionStatus => RegionStatus.value === status).label;
  return (
    <Grid item xs={isMobile() ? 12 : 5}>
      <RegionsContainer container direction="column" spacing={1} wrap="nowrap">
        {regions.map((region, index) => (
          <Grid item key={region.name} xs={12}>
            <Grid alignItems="center" container justifyContent="space-between" wrap="nowrap">
              <Grid item>
                <Typography noWrap>{`${index + 1}. ${region.name}`}</Typography>
              </Grid>
              <Grid item>
                <SatutsContainer data-status={region.status}>
                  <Typography>{getStatusLabel(region.status)}</Typography>
                </SatutsContainer>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </RegionsContainer>
    </Grid>
  );
};

export default RegionsStatus;