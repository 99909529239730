import { faInfoCircle, faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const LinkContainer = styled.div`
  margin: 11px;
  cursor: pointer;
  background-color: var(--white);
  border-radius: 20px;
  padding: 0 30px;

  &:hover {
    background-color: var(--grey);
  }
`;

const LabelContainer = styled.div`
  color: var(--primary-color);
  font-size: 18px;
  font-weight: bold;
`;

const IconContainer = styled(Grid)`
  font-size: 12px;
  color: var(--primary-color);
`;

const ExternLink = ({
  link, label
}) => (
  <LinkContainer onClick={() => { window.open(link, '_blank'); }}>
    <Grid alignItems="center" container justifyContent="space-between" spacing={2} wrap="nowrap">
      <IconContainer item>
        <FontAwesomeIcon icon={faLink} size="2x" />
      </IconContainer>
      <Grid item>
        <LabelContainer>{label}</LabelContainer>
      </Grid>
      <IconContainer item>
        <FontAwesomeIcon icon={faInfoCircle} size="2x" />
      </IconContainer>
    </Grid>
  </LinkContainer>
);

export default ExternLink;