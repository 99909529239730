import { REFRESH_TOKEN_NAME } from 'utils/constants';

const getCookie = name => {
  const allCookies = document.cookie.split(';');

  if (allCookies.length > 0) {
    const cookie = allCookies.find(c => c.trim().startsWith(name));

    if (cookie) {
      // We get a cookie like that :
      // refreshToken=peHjJxWhrw5xN9QIMP32SvDhurOgsYax9ZX0zwc/aeE=
      // so we get the string from the (name.length) + (1 for the '=')
      return cookie.trim().substring(name.length + 1);
    }

    return null;
  }

  return null;
};

const getRootDomain = () => {
  let rootDomain = '';
  if (window.location.hostname !== 'localhost') {
    const domainParts = window.location.hostname.split('.');
    rootDomain = `domain= ${domainParts.slice(-2).join('.')}`;
  }
  return rootDomain;
};

const setCookie = (name, value, expires, maxAge = 3600) => {
  const rootDomain = getRootDomain();
  if (!expires) {
    const cookieMaxAge = `${name}=${value}; max-age=${maxAge}${window.location.host.includes('localhost') ? '' : `; secure; sameSite=None; ${rootDomain}`}`;
    document.cookie = cookieMaxAge;
    return;
  }
  const cookieExpire = `${name}=${value}; expires=${expires}${window.location.host.includes('localhost') ? '' : `; secure; sameSite=None; ${rootDomain}`}`;
  document.cookie = cookieExpire;
};

const deleteCookie = name => {
  const cookieDelete = `${name}=; expires=${new Date('1970-01-01').toUTCString()}`;
  document.cookie = cookieDelete;
};

const createCookies = cookies => {
  const expiresDate = new Date(cookies.refreshTokenExpiration).toUTCString();

  return new Promise(resolve => {
    setCookie('unicemToken', cookies.token);
    setCookie(REFRESH_TOKEN_NAME, cookies.refreshToken, expiresDate);
    resolve(true);
  });
};

export const CookieHelper = {
  getCookie,
  setCookie,
  deleteCookie,
  createCookies
};