import React from 'react';
import {
  Grid, Typography
} from '@material-ui/core';
import {
  LargeWrapper
} from 'components';

import styled from 'styled-components';
import { isMobile } from 'utils';
import { APP_VERSION, INTRANET_URL } from 'utils/constants';

const HeaderContainer = styled.div`
  position: relative;
  background-color:  var(--primary-color);
  box-shadow: 0 0px 10px rgba(0,0,0,0.2);
  z-index: var(--zindexBig);
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    right: -46px;
    top: 5px;
    width: 72px;
    height: 72px;

    @media (max-width: 920px) {
      display: none;
    }
  }
`;

const Logo = styled.a`
  cursor: pointer;
  .logo {
    top: 28px;
    left: 3%;
    width: 120px;
    margin-right: 4rem;
    position: absolute;
    z-index: 101;
  }

  @media (max-width: 990px) {
    .MuiGrid-item {
      padding: 4px;
    }

    .logo {
      width: 70px;
      margin: 0.5rem 1rem;
    }
  }

  @media (max-width: 560px) {
    .logo {
      width: 50px;
      margin: 0;
    }
  }
`;

const BgHeader = styled.img`
  top: 0;
  right: 0;
  height: 77px;
  position: absolute;
  z-index: -100;

  @media (max-width: 990px) {
    width: 70px;
    visibility: hidden;
    margin: 0.5rem 1rem;
  }
`;

const StyledHeaderTunnel = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;

  h1, span {
    color: var(--white);
  }

  @media (max-width: 768px) {
    padding: 3rem 0;
    margin: -1rem auto;
    background-size: 100% 100%;

    h1 {
      font-size: 1.8rem;
    }
  }
`;

const Header = () => (
  <header>
    <Logo href={INTRANET_URL} rel="noopener noreferrer">
      <img alt="UNICEM" className="logo" src="/assets/images/logo-2021.png" />
    </Logo>
    <HeaderContainer>
      <BgHeader alt="" className="bg-header" src="/assets/images/bg-header-wizard.png" />
      <LargeWrapper>
        <Grid alignItems="center" container spacing={isMobile() ? 1 : 4} wrap="nowrap">
          <Grid item xs>
            <StyledHeaderTunnel container>
              <Grid item>
                <Typography variant="h1">Nouvelle modification du fichier central</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">{APP_VERSION}</Typography>
              </Grid>
            </StyledHeaderTunnel>
          </Grid>
        </Grid>
      </LargeWrapper>
    </HeaderContainer>
  </header>
);

export default Header;