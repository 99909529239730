import { useEffect, useState } from 'react';

import { DocumentHelper } from './DocumentHelper';
import { FormHelper } from './FormHelper';
import { RequestHelper } from './RequestHelper';
import { CookieHelper } from './CookieHelper';
import { AmendmentHelper } from './AmendmentHelper';

const useDebounce = (value, delay = 2000) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const extractNextId = array => {
  if (array.length === 0) return 0;

  const ids = array.map(object => object.id);
  return Math.max(...ids) + 1;
};

export {
  DocumentHelper,
  FormHelper,
  RequestHelper,
  useDebounce,
  extractNextId,
  CookieHelper,
  AmendmentHelper
};