import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip as MatTooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { translate } from 'utils';

const Tooltip = ({
  label, icon, placement, size
}) => (
  <MatTooltip
    aria-label={translate(label)}
    placement={placement}
    title={translate(label)}
  >
    <IconButton
      aria-label={translate(label)}
      color="secondary"
      size={size}
    >
      <FontAwesomeIcon icon={icon} />
    </IconButton>
  </MatTooltip>
);

Tooltip.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.shape({}),
  placement: PropTypes.oneOf([
    'top',
    'bottom',
    'left',
    'right'
  ]),
  size: PropTypes.oneOf([
    'small',
    'medium'
  ])
};

Tooltip.defaultProps = {
  icon: faQuestionCircle,
  placement: 'right',
  size: 'small'
};

export default Tooltip;